@import "../fonts/font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply p-0 m-0 box-border scroll-smooth font-gilroy;
}

body {
  @apply overflow-x-hidden;
}

*::-webkit-scrollbar {
  width: 6px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background-color: #1b7ca0;
  border-radius: 10px;
}
